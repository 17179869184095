var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                { staticClass: "mt-4", attrs: { header: "Album data" } },
                [
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          name: "Attributes.ReleaseType",
                          defs: _vm.fieldDefs,
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _c("page-snippet", {
                    staticClass: "mt-5",
                    attrs: { name: "album-intro" }
                  }),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "TitleLanguage",
                          model: _vm.release,
                          errors: _vm.errors
                        },
                        on: { change: _vm.updateAttribution }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Title",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "VersionTitle",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("contributors", {
                        staticClass: "mt-5",
                        attrs: {
                          copyToTracks: false,
                          defs: _vm.fieldDefs,
                          type: "album",
                          model: _vm.release,
                          validationErrors: _vm.errors
                        },
                        on: { change: _vm.updateAttribution }
                      })
                    : _vm._e(),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "UPCorEAN",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "ArtistURL",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "CatalogNr",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "VolumeNr",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "OriginCountry",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "Label",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "LabelURL",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "PLine",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "CLine",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "OriginalReleaseFormat",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "OriginalReleaseDate",
                      date: true,
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "Attributes.ReleasePriority",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _c("b-alert", {
                    attrs: {
                      show:
                        _vm.getAttribute(_vm.release, "ReleasePriority") ==
                        "High",
                      variant: "warning"
                    },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$root.$data.pageSnippets["priority-release"]
                      )
                    }
                  }),
                  _vm.getAttribute(_vm.release, "ReleasePriority") != "High"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "ReleaseDate",
                          date: true,
                          minDate: _vm.earliestReleaseDate,
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "ReleasePriority") == "High"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.PriorityRequestDate",
                          date: true,
                          minDate: new Date(),
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "ReleasePriority") == "High"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.PriorityRequest",
                          memo: true,
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "Attributes.PreOrderDays",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _vm.getAttribute(_vm.release, "PreOrderDays")
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.CoverArtPreviewDays",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "PreOrderDays")
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.TrackListingPreviewDays",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "PreOrderDays")
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.PreOrderPreViews",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Tags.TopLevel",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Tags.RecordingVersion",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Tags.MainGenre",
                          model: _vm.release,
                          errors: _vm.errors
                        },
                        on: {
                          change: function($event) {
                            return _vm.setTag(_vm.release, "SubGenre", null)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Tags.SubGenre",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.release.Tracks.length > 1
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Tags.Occasion",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  ["Album", "Compilation"].includes(
                    _vm.getAttribute(_vm.release, "ReleaseType")
                  )
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Tags.AlbumPriceTier",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _c("page-snippet", {
                    staticClass: "mt-5",
                    attrs: { name: "promotion" }
                  }),
                  _c("field", {
                    attrs: {
                      defs: _vm.fieldDefs,
                      name: "Attributes.Promotion",
                      model: _vm.release,
                      errors: _vm.errors
                    }
                  }),
                  _vm.getAttribute(_vm.release, "Promotion") == "True"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.MarketingDrivers",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "Promotion") == "True"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.Promotional Activities",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "Promotion") == "True"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.MarketingSynopsis",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "Promotion") == "True"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.TourDetails",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "Promotion") == "True"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.Instagram",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "Promotion") == "True"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.Facebook",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "Promotion") == "True"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.YouTube",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e(),
                  _vm.getAttribute(_vm.release, "Promotion") == "True"
                    ? _c("field", {
                        attrs: {
                          defs: _vm.fieldDefs,
                          name: "Attributes.MarketingComment",
                          model: _vm.release,
                          errors: _vm.errors
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              Object.keys(_vm.errors).length
                ? _c(
                    "b-alert",
                    {
                      staticClass: "mt-4",
                      attrs: { show: "", variant: "danger" }
                    },
                    [
                      _vm._v(
                        " The release contains errors. Please scroll up and correct them. "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { disabled: !_vm.readyToProceed },
                  on: { click: _vm.proceed }
                },
                [_vm._v("Proceed with the next step")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }